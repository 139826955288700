define("ember-svg-jar/inlined/bolt-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.079 3.44c.27-.421.921-.23.921.269V9.5h4.086a.5.5 0 01.422.77L11.92 20.56c-.27.422-.921.23-.921-.269v-5.79H6.914a.5.5 0 01-.421-.77l6.586-10.292z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});