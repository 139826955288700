define("@additive-apps/ui/utils/table-util", ["exports", "@ember/utils", "@ember/array", "date-fns", "date-fns/locale", "@additive-apps/ui/utils/date-util", "@additive-apps/ui/utils/format-currency"], function (_exports, _utils, _array, _dateFns, _locale, _dateUtil, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatTableRows = _exports.formatCollapsibleTableRows = _exports.DEFAULT_FORMATS = void 0;
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const DEFAULT_FORMATS = _exports.DEFAULT_FORMATS = {
    date: {
      format: 'dd.MM.yyyy',
      locale: _locale.de
    },
    number: {
      locale: 'de-DE',
      options: {
        maximumFractionDigits: 2
      }
    },
    currency: {
      locale: 'de-DE',
      options: {
        currency: 'EUR'
      }
    },
    percentage: {
      locale: 'de-DE',
      options: {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      }
    },
    custom: val => val
  };

  /**
   * formatting functions for the tables
   *
   * @type {Object}
   */
  const FORMATS = {
    currency: (val, formats) => (0, _formatCurrency.formatCurrency)({
      value: val,
      currency: formats.currency.options.currency,
      locales: formats.currency.locale,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      divider: 1
    }),
    currency_cent: (val, formats) => {
      const value = val / 100;
      return FORMATS.currency(value, formats);
    },
    date: (val, formats) => {
      const date = (0, _dateUtil.parseDate)(val);
      return (0, _dateFns.format)(date, formats.date.format, {
        locale: formats.date.locale
      });
    },
    number: (val, formats) => val.toLocaleString(formats.number.locale, _objectSpread({}, formats.number.options)),
    percentage: (val, formats) => (val / 100).toLocaleString(formats.percentage.locale, _objectSpread({
      style: 'percent'
    }, formats.percentage.options)),
    custom: (val, formats, customKey) => {
      if (customKey) {
        return formats.custom[customKey](val);
      }
      return formats.custom(val);
    }
  };

  /**
   * formats a value with a given format
   *
   * @function formatValue
   * @param {Number} value
   * @param {Function} format
   * @param {String} customKey the custom formatting key
   * @return {String} formatted value
   */
  const formatValue = function formatValue(value) {
    let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    let formats = arguments.length > 2 ? arguments[2] : undefined;
    let customKey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    if ((0, _utils.isNone)(value)) {
      return '—';
    } else if (format) {
      return format(value, formats, customKey);
    } else {
      if (isNaN(value)) {
        return value;
      }
      const number = parseFloat(value);
      return FORMATS.number(number, formats);
    }
  };

  /**
   * formats the given cell
   *
   * @function formatCell
   * @param {Object|String} content
   * @return {String|Object} the formatted cell
   */
  const formatCell = (content, formats) => {
    // format the value directy if the content is not an object or no formatting is passed
    if (!content || typeof content !== 'object') {
      return formatValue(content, null, formats);
    }
    const {
      value,
      percentage,
      format,
      customKey,
      tooltip
    } = content;
    const val = format === 'custom' ? content : value;

    // format the value with the right format
    const formattedValue = format ? formatValue(val, FORMATS[format], formats, customKey) : formatValue(value, null, formats);

    // format percentage if it is passed
    if (Object.keys(content).indexOf('percentage') >= 0) {
      return {
        value: formattedValue,
        percentage: formatValue(percentage, FORMATS['percentage'], formats),
        tooltip
      };
    }
    if (tooltip) {
      return {
        value: formattedValue,
        tooltip
      };
    }
    return formattedValue;
  };

  /**
   * iterates throgh rows and formats them
   *
   * @function formatTableRows
   * @param {Array} row
   * @param {Object} customFormats
   */
  const formatTableRows = (rows, customFormats) => {
    const formats = Object.assign({}, DEFAULT_FORMATS, customFormats || {});
    return rows.map(row => {
      if ((0, _array.isArray)(row)) {
        return row.map(item => formatCell(item, formats));
      }
      // don't format if row is not an array
      return row;
    });
  };

  /**
   * formats a given row and calls itself recursively on its sub-rows
   *
   * @function formatCollapsibleTableRow
   * @param {Object} row
   * @param {Object} formats
   * @return {Object}
   */
  _exports.formatTableRows = formatTableRows;
  const formatCollapsibleTableRow = (row, formats) => {
    if (row && row.rowData) {
      row.rowData = row.rowData.map(item => formatCell(item, formats));
    }
    if (row && row.subData) {
      row.subData = row.subData.map(row => formatCollapsibleTableRow(row, formats));
    }
    return row;
  };

  /**
   * formats the collapsible table rows
   *
   * @function formatCollapsibleTableRows
   * @param {Array} rows
   * @param {Object} customFormats
   * @return {Array} the formatted rows
   */
  const formatCollapsibleTableRows = (rows, customFormats) => {
    const formats = Object.assign({}, DEFAULT_FORMATS, customFormats || {});
    return rows.map(row => formatCollapsibleTableRow(row, formats));
  };
  _exports.formatCollapsibleTableRows = formatCollapsibleTableRows;
});