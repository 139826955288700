define("@additive-apps/configurator/components/aw-configurator", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-arg-types", "prop-types", "ember-concurrency", "lodash.merge", "@additive-apps/configurator/utils/helpers", "@additive-apps/ui/utils/dom-util"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberArgTypes, _propTypes, _emberConcurrency, _lodash, _helpers, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-column w-100 h-100">
    <div class="navigation-bar-spacer flex bg-white">
      <UiNavigationBar @onClose={{this.onClose}} @isMenuHidden={{true}} @isSentinelHidden={{true}} as |navbar|>
        {{yield (hash navbarAction=navbar.action)}}
  
        <navbar.button
          @icon="save"
          @label={{t "global.actions.save"}}
          @isDisabled={{this.isReadOnly}}
          @onClick={{this.onSave}}
        />
      </UiNavigationBar>
    </div>
  
    <div class="configurator-container flex flex-grow-1 w-100 ohidden">
      {{yield
        (hash
          styleEditor=(component "aw-configurator/style-editor" isReadOnly=this.isReadOnly)
          contentEditor=(component "aw-configurator/content-editor" isReadOnly=this.isReadOnly)
          editor=(component "aw-configurator/editor" isReadOnly=this.isReadOnly)
          onUpdate=this.update
          styles=this._styles
          contentPreview=(component "ui-blank-template" class="aw-configurator__content-preview flex w-100" tagName="div")
          preview=(component
            "aw-configurator/preview"
            isLoading=this._setup.isRunning
            hasError=this._hasError
            styles=this._styles
            didInsert=(perform this._setup)
            onRetry=(perform this._setup)
            isStyleEditor=this.isStyleEditor
          )
        )
      }}
    </div>
  </div>
  */
  {
    "id": "a9whJXKg",
    "block": "[[[10,0],[14,0,\"flex flex-column w-100 h-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"navigation-bar-spacer flex bg-white\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@onClose\",\"@isMenuHidden\",\"@isSentinelHidden\"],[[30,0,[\"onClose\"]],true,true]],[[\"default\"],[[[[1,\"\\n      \"],[18,2,[[28,[37,2],null,[[\"navbarAction\"],[[30,1,[\"action\"]]]]]]],[1,\"\\n\\n      \"],[8,[30,1,[\"button\"]],null,[[\"@icon\",\"@label\",\"@isDisabled\",\"@onClick\"],[\"save\",[28,[37,3],[\"global.actions.save\"],null],[30,0,[\"isReadOnly\"]],[30,0,[\"onSave\"]]]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"configurator-container flex flex-grow-1 w-100 ohidden\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,2],null,[[\"styleEditor\",\"contentEditor\",\"editor\",\"onUpdate\",\"styles\",\"contentPreview\",\"preview\"],[[50,\"aw-configurator/style-editor\",0,null,[[\"isReadOnly\"],[[30,0,[\"isReadOnly\"]]]]],[50,\"aw-configurator/content-editor\",0,null,[[\"isReadOnly\"],[[30,0,[\"isReadOnly\"]]]]],[50,\"aw-configurator/editor\",0,null,[[\"isReadOnly\"],[[30,0,[\"isReadOnly\"]]]]],[30,0,[\"update\"]],[30,0,[\"_styles\"]],[50,\"ui-blank-template\",0,null,[[\"class\",\"tagName\"],[\"aw-configurator__content-preview flex w-100\",\"div\"]]],[50,\"aw-configurator/preview\",0,null,[[\"isLoading\",\"hasError\",\"styles\",\"didInsert\",\"onRetry\",\"isStyleEditor\"],[[30,0,[\"_setup\",\"isRunning\"]],[30,0,[\"_hasError\"]],[30,0,[\"_styles\"]],[28,[37,5],[[30,0,[\"_setup\"]]],null],[28,[37,5],[[30,0,[\"_setup\"]]],null],[30,0,[\"isStyleEditor\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"navbar\",\"&default\"],false,[\"ui-navigation-bar\",\"yield\",\"hash\",\"t\",\"component\",\"perform\"]]",
    "moduleName": "@additive-apps/configurator/components/aw-configurator.hbs",
    "isStrictMode": false
  });
  /**
   * The actual configurator HOC.
   *
   * the primary goal is to load the widget instance and updating it's styles.
   *
   * @class aw-configurator
   * @module aw-configurator
   */
  let AwConfigurator = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.object), _dec5 = (0, _emberArgTypes.arg)(_propTypes.object), _dec6 = (0, _emberArgTypes.arg)(_propTypes.object), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var _this$config, _this$styles, _this$options;
      const tasks = [];
      tasks.push(this.setupTask());
      tasks.push((0, _emberConcurrency.timeout)(250));
      yield (0, _emberConcurrency.all)(tasks);

      /*
       * Wait for next run loop, as `setupTask` might update attributes
       * and those are missing in this run loop, will be applied in next run loop
       */
      yield (0, _domUtil.nextTick)();
      this._config = (_this$config = this.config) !== null && _this$config !== void 0 && _this$config.proxy ? this.config.content : this.config;
      this._styles = (_this$styles = this.styles) !== null && _this$styles !== void 0 && _this$styles.proxy ? this.styles.content : this.styles;
      this._options = (_this$options = this.options) !== null && _this$options !== void 0 && _this$options.proxy ? this.options.content : this.options;
      const widgetStyles = this.getSyncStyles();
      !this.widgetLess && this.instance.init(this._options, widgetStyles);
      this._hasError = false;
    } catch (e) {
      this._hasError = true;
    }
  }), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _dec11 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AwConfigurator extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
      /**
       * The typography defined in the corporate design
       *
       * @argument corporateDesignTypography
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "corporateDesignTypography", _descriptor2, this);
      /**
       * Whether the editor is read-only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor3, this);
      /**
       * the widget config
       *
       * @argument config
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "config", _descriptor4, this);
      /**
       * the widget options
       *
       * @argument options
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "options", _descriptor5, this);
      /**
       * the widget styles
       *
       * @argument styles
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "styles", _descriptor6, this);
      /**
       * the widget instance
       *
       * @argument instance
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "instance", _descriptor7, this);
      /**
       * Whether the configurator operates with a widget
       *
       * @argument widgetLess
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "widgetLess", _descriptor8, this);
      /**
       * internal styles displaying the sync colors if sync is enabled
       *
       * @property _styles
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "_styles", _descriptor9, this);
      /**
       * internal options displaying the sync colors if sync is enabled
       *
       * @property _options
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "_options", _descriptor10, this);
      /**
       * internal config
       *
       * @property _config
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "_config", _descriptor11, this);
      /**
       * the error state
       *
       * @property _hasError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_hasError", _descriptor12, this);
      /**
       * Setup configurator;
       *
       * Callback which we passe down to the actual preview to make sure that
       * the element in which we want to render the content has been inserted into
       * the DOM
       *
       * @type {Task}
       * @function _setup
       * @private
       */
      _initializerDefineProperty(this, "_setup", _descriptor13, this);
      _initializerDefineProperty(this, "setupTask", _descriptor14, this);
      _initializerDefineProperty(this, "onSave", _descriptor15, this);
      _initializerDefineProperty(this, "onClose", _descriptor16, this);
    }
    get modelName() {
      var _this$router$currentR;
      const model = (_this$router$currentR = this.router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : _this$router$currentR.attributes;
      if (!model) {
        return null;
      }
      return (model === null || model === void 0 ? void 0 : model.name) || (model === null || model === void 0 ? void 0 : model.title);
    }
    /**
     * the computed styles displaying the sync values if enabled and the set ones instead
     *
     * @function getSyncStyles
     * @return {Object}
     */
    getSyncStyles() {
      const newColors = {};
      const newTypography = {};
      if (this.styles) {
        const {
          colors,
          typography
        } = this._styles;

        // set color styles
        for (let key of Object.keys(colors)) {
          const isSync = colors[key].sync;
          if (isSync) {
            newColors[key] = {
              color: colors[key].syncedColor,
              contrastColor: colors[key].syncedContrastColor
            };
          }
        }

        // set typography styles
        for (let key of Object.keys(typography)) {
          const isSync = typography[key].sync;
          if (isSync && this.corporateDesignTypography) {
            const {
              syncedTypography
            } = typography[key];
            if (syncedTypography && this.corporateDesignTypography[syncedTypography]) {
              newTypography[key] = {
                fontFamily: this.corporateDesignTypography[syncedTypography].fontFamily.replace('.', ''),
                lineHeight: this.corporateDesignTypography[syncedTypography].lineHeight,
                fontSize: this.corporateDesignTypography[syncedTypography].fontSize,
                color: this.corporateDesignTypography[syncedTypography].color,
                serif: this.corporateDesignTypography[syncedTypography].serif,
                uppercase: this.corporateDesignTypography[syncedTypography].uppercase,
                letterSpacing: this.corporateDesignTypography[syncedTypography].letterSpacing
              };
            }
          } else {
            const {
              fontFamily
            } = typography[key];
            // normalize custom font family keys to valid css properties
            if (fontFamily.startsWith('primary') || fontFamily.startsWith('secondary')) {
              newTypography[key] = {
                fontFamily: typography[key].fontFamily.replace('.', '')
              };
            }
          }
        }
      }
      return (0, _lodash.default)({}, this._styles, {
        colors: newColors,
        typography: newTypography
      });
    }
    /**
     * Updates the widgets styles and options
     *
     * @function update
     * @param {String} key the stringified object depth
     * @param {Any} value actual value that gets assigned
     * @param {String} type styles or options for now
     */
    update(key, value) {
      let type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'styles';
      /*
       * The initial options, styles etc. should always be merged with the new value
       * the update on the widgets side should always happen as a whole and it shouldn't bother
       * for immutability etc.
       */
      const newProp = (0, _helpers.pathToObject)(key, value);
      if (type === 'options') {
        const newOptions = (0, _lodash.default)({}, this._options, newProp);
        this._options = newOptions;
        if (!this.widgetLess) {
          const widgetsStyles = this.getSyncStyles();
          this.instance.update(newOptions, widgetsStyles);
        }
      } else if (type === 'config') {
        const newConfig = (0, _lodash.default)({}, this._config, newProp);
        this._config = newConfig;
      } else {
        const newStyles = (0, _lodash.default)({}, this._styles, newProp);
        this._styles = newStyles;
        if (!this.widgetLess) {
          const widgetsStyles = this.getSyncStyles();
          this.instance.update(this._options, widgetsStyles);
        }
      }
      return {
        options: this._options,
        config: this._config,
        styles: this._styles
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "corporateDesignTypography", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "options", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "styles", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "instance", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "widgetLess", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_styles", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_options", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_config", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_hasError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_setup", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "setupTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AwConfigurator);
});