define("@additive-apps/ui/utils/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = void 0;
  /**
   * Formats a currency value.
   *
   * @param {Object} options - An object containing the formatting options.
   * @param {number} options.value - The numeric value to format.
   * @param {string} [options.currency='EUR'] - The currency code (e.g., 'USD', 'EUR'). Sets the currency symbol of the output string.
   * @param {string} [options.locales='de-DE'] - The locale string (e.g., 'en-US', 'de-DE'). Sets the format of the output string.
   * @param {number} [options.minimumFractionDigits=2] - The minimum number of fraction digits.
   * @param {number} [options.maximumFractionDigits=2] - The maximum number of fraction digits.
   * @param {number} [options.divider=100] - The value by which to divide the input value.
   *
   * @returns {string} - The formatted currency string.
   */
  const formatCurrency = _ref => {
    let {
      value,
      currency = 'EUR',
      locales = 'de-DE',
      minimumFractionDigits = 2,
      maximumFractionDigits = 2,
      divider = 100
    } = _ref;
    if (isNaN(value)) {
      return value.toString();
    }
    let formattedAmount = (value / divider).toLocaleString(locales, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    switch (currency) {
      case 'EUR':
        return formattedAmount = `${formattedAmount}€`;
      case 'CHF':
        return formattedAmount = `CHF ${formattedAmount}`;
      case 'USD':
        return `$${formattedAmount}`;
      default:
        throw new Error(`[format-currency] given currency is not supported. Currency send: ${currency}`);
    }
  };
  _exports.formatCurrency = formatCurrency;
});