define("@additive-apps/ui/templates/components/ui-form-control", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "q3ju5LsO",
    "block": "[[[41,[51,[30,0,[\"isPlain\"]]],[[[1,\"  \"],[8,[39,1],null,[[\"@for\",\"@value\",\"@helperText\",\"@onHelp\"],[[30,0,[\"inputElementId\"]],[30,0,[\"label\"]],[30,0,[\"helperText\"]],[30,0,[\"onHelp\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,1,[[28,[37,3],null,[[\"content\"],[[50,\"ui-blank-template\",0,null,[[\"tagName\",\"class\"],[\"div\",[28,[37,5],[\"ui-form-item relative flex items-center font-md \",[52,[30,0,[\"active\"]],\"ui-form-item--active\",\"\"],[52,[30,0,[\"textarea\"]],\"ui-form-item__textarea\",\"\"]],null]]]]]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"isPlain\"]]],[[[1,\"  \"],[8,[39,7],null,[[\"@showCount\",\"@value\",\"@state\",\"@charCount\",\"@maxlength\",\"@recommendedLength\"],[[30,0,[\"showCount\"]],[30,0,[\"message\"]],[30,0,[\"computedState\"]],[30,0,[\"charCount\"]],[30,0,[\"maxlength\"]],[30,0,[\"recommendedLength\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showRecommendedLengthWarning\"]],[[[1,\"  \"],[8,[39,8],null,[[\"@classNames\",\"@title\",\"@icon\",\"@isWarning\"],[\"mt2\",[52,[30,0,[\"recommendedWarning\"]],[30,0,[\"recommendedWarning\"]],[28,[37,9],[\"uiFormControl.recommendedLengthWarning\"],[[\"recommendedLength\"],[[30,0,[\"recommendedLength\"]]]]]],\"warning-state\",true]],null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"unless\",\"ui-label\",\"yield\",\"hash\",\"component\",\"concat\",\"if\",\"ui-form-message\",\"ui-inline-notification\",\"t\"]]",
    "moduleName": "@additive-apps/ui/templates/components/ui-form-control.hbs",
    "isStrictMode": false
  });
});