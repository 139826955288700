define("ember-svg-jar/inlined/confirmed-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 5.022c-1.78 1.525-3.608 2.46-5.477 2.768-.251 4.6 1.544 8.066 5.476 10.577 3.937-2.515 5.73-5.982 5.479-10.577-1.872-.309-3.7-1.243-5.478-2.768zm3.177 4.655l-.354-.354a.5.5 0 00-.707 0L11.5 11.94l-1.116-1.116a.5.5 0 00-.707 0l-.354.354a.5.5 0 000 .707l1.823 1.823a.5.5 0 00.708 0l3.323-3.323a.5.5 0 000-.707z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.392 3.357a.572.572 0 00-.784 0c-1.991 1.876-3.982 2.886-5.973 3.03a.583.583 0 00-.547.511c-.57 5.738 1.637 10.088 6.619 13.05.18.107.405.107.584 0 4.983-2.962 7.19-7.313 6.622-13.05a.586.586 0 00-.549-.512c-1.99-.143-3.982-1.153-5.972-3.03zM6.522 7.79C8.393 7.481 10.22 6.547 12 5.022c1.779 1.525 3.606 2.46 5.478 2.768.252 4.595-1.542 8.062-5.48 10.577-3.931-2.511-5.726-5.976-5.475-10.577z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});