define("@additive-apps/configurator/components/ae-configurations", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-arg-types", "prop-types", "ember-animated/transitions/fade"], function (_exports, _component, _templateFactory, _component2, _object, _emberArgTypes, _propTypes, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this._configurations as |styleGroup|}}
    <PanelGroup
      @classNames={{if (eq this._configurations.length 1) "flex flex-column flex-grow-1"}}
      @title={{unless styleGroup.hideGroupName styleGroup.name}}
      @onButtonClick={{styleGroup.onButtonClick}}
      @buttonText={{styleGroup.buttonText}} as |group|
    >
      {{#each styleGroup.properties as |style index|}}
        <AnimatedContainer class="ohidden">
          {{#animated-if (not style.isHidden) use=this._transition}}
            <AeConfigurations::Item
              @buttonOptions={{this.buttonOptions}}
              @colorOptions={{this.colorOptions}}
              @fontOptions={{this.fontOptions}}
              @fonts={{this.fonts}}
              @groupItem={{group.item}}
              @isContentEditor={{@isContentEditor}}
              @isFirstItem={{eq index 0}}
              @isReadOnly={{@isReadOnly}}
              @item={{style}}
              @onChange={{this.onChange}}
              @onSelectOffer={{this.onSelectOffer}}
              @onSelectPost={{this.onSelectPost}}
              @onSelectVoucher={{this.onSelectVoucher}}
              @onSelectSurvey={{this.onSelectSurvey}}
              @allowedLinkPlaceholders={{@allowedLinkPlaceholders}}
              @allowedTextPlaceholders={{@allowedTextPlaceholders}}
            >
              {{yield}}
            </AeConfigurations::Item>
          {{/animated-if}}
        </AnimatedContainer>
      {{/each}}
    </PanelGroup>
  {{/each}}
  */
  {
    "id": "O7q2yp3y",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_configurations\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@classNames\",\"@title\",\"@onButtonClick\",\"@buttonText\"],[[52,[28,[37,4],[[30,0,[\"_configurations\",\"length\"]],1],null],\"flex flex-column flex-grow-1\"],[52,[51,[30,1,[\"hideGroupName\"]]],[30,1,[\"name\"]]],[30,1,[\"onButtonClick\"]],[30,1,[\"buttonText\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"properties\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],[[24,0,\"ohidden\"]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,7],[[28,[37,8],[[30,3,[\"isHidden\"]]],null]],[[\"use\"],[[30,0,[\"_transition\"]]]],[[\"default\"],[[[[1,\"          \"],[8,[39,9],null,[[\"@buttonOptions\",\"@colorOptions\",\"@fontOptions\",\"@fonts\",\"@groupItem\",\"@isContentEditor\",\"@isFirstItem\",\"@isReadOnly\",\"@item\",\"@onChange\",\"@onSelectOffer\",\"@onSelectPost\",\"@onSelectVoucher\",\"@onSelectSurvey\",\"@allowedLinkPlaceholders\",\"@allowedTextPlaceholders\"],[[30,0,[\"buttonOptions\"]],[30,0,[\"colorOptions\"]],[30,0,[\"fontOptions\"]],[30,0,[\"fonts\"]],[30,2,[\"item\"]],[30,5],[28,[37,4],[[30,4],0],null],[30,6],[30,3],[30,0,[\"onChange\"]],[30,0,[\"onSelectOffer\"]],[30,0,[\"onSelectPost\"]],[30,0,[\"onSelectVoucher\"]],[30,0,[\"onSelectSurvey\"]],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n            \"],[18,9,null],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"      \"]],[]]]]],[1,\"\\n\"]],[3,4]],null],[1,\"  \"]],[2]]]]],[1,\"\\n\"]],[1]],null]],[\"styleGroup\",\"group\",\"style\",\"index\",\"@isContentEditor\",\"@isReadOnly\",\"@allowedLinkPlaceholders\",\"@allowedTextPlaceholders\",\"&default\"],false,[\"each\",\"-track-array\",\"panel-group\",\"if\",\"eq\",\"unless\",\"animated-container\",\"animated-if\",\"not\",\"ae-configurations/item\",\"yield\"]]",
    "moduleName": "@additive-apps/configurator/components/ae-configurations.hbs",
    "isStrictMode": false
  });
  let AeConfigurationsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.array), _dec2 = (0, _emberArgTypes.arg)(_propTypes.array), _dec3 = (0, _emberArgTypes.arg)(_propTypes.array), _dec4 = (0, _emberArgTypes.arg)(_propTypes.array), _dec5 = (0, _emberArgTypes.arg)(_propTypes.array), _dec6 = (0, _emberArgTypes.arg)(_propTypes.object), _dec7 = (0, _emberArgTypes.arg)(_propTypes.array), _dec8 = (0, _emberArgTypes.arg)(_propTypes.object), _dec9 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _dec11 = (0, _emberArgTypes.arg)(_propTypes.func), _dec12 = (0, _emberArgTypes.arg)(_propTypes.func), _dec13 = (0, _emberArgTypes.arg)(_propTypes.func), _dec14 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AeConfigurationsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the placeholders that are allowed as link in editor and link items
       *
       * @argument allowedLinkPlaceholders
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "allowedLinkPlaceholders", _descriptor, this);
      /**
       * the placeholders that are allowed in text
       *
       * @argument allowedTextPlaceholders
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "allowedTextPlaceholders", _descriptor2, this);
      _initializerDefineProperty(this, "buttonOptions", _descriptor3, this);
      _initializerDefineProperty(this, "colorOptions", _descriptor4, this);
      _initializerDefineProperty(this, "configurations", _descriptor5, this);
      _initializerDefineProperty(this, "errors", _descriptor6, this);
      _initializerDefineProperty(this, "fontOptions", _descriptor7, this);
      _initializerDefineProperty(this, "fonts", _descriptor8, this);
      /**
       * whether the configurations are used in content editor
       *
       * @argument isContentEditor
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isContentEditor", _descriptor9, this);
      _defineProperty(this, "_transition", _fade.default);
      _initializerDefineProperty(this, "onChange", _descriptor10, this);
      _initializerDefineProperty(this, "onSelectOffer", _descriptor11, this);
      _initializerDefineProperty(this, "onSelectPost", _descriptor12, this);
      _initializerDefineProperty(this, "onSelectSurvey", _descriptor13, this);
      _initializerDefineProperty(this, "onSelectVoucher", _descriptor14, this);
    }
    get _configurations() {
      return this.configurations.map(group => {
        let isHidden = true;
        let hideGroupName = true;
        group.properties.forEach(property => {
          if (!property.isHidden) {
            isHidden = false;
          }
          if (property.type !== 'text') {
            hideGroupName = false;
          }
        });
        (0, _object.set)(group, 'hideGroupName', hideGroupName && group.properties.length === 1);
        return isHidden ? null : group;
      }).filter(group => group);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "allowedLinkPlaceholders", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowedTextPlaceholders", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "buttonOptions", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "colorOptions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "configurations", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errors", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fontOptions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fonts", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isContentEditor", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onSelectOffer", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onSelectPost", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onSelectSurvey", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      var _this = this;
      return function () {
        _this.onChange(...arguments);
      };
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onSelectVoucher", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AeConfigurationsComponent);
});